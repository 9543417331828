import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import {
  AccordionHeader,
  AccordionNode,
  AccordionPanel,
  AccordionWithHeader,
} from "react-accordion-with-header"
import "../../styles/component/faq.scss"
import "./Accordian-new/custom-accordion-clone.scss"
import SVGIcon from "./SVGIcon"

const NewAccordian = props => {
  const {
    question,
    answerrt,
    images,
    linkText,
    slug,
  } = props.faqComponent.faqs[0]

  const [accInd, setAccInd] = useState(0)

  useEffect(() => {
    const getac = document.querySelectorAll(".qn-wrap")

    if (props.isVisible)
      for (let i = 0; i < getac.length; i++) {
        setTimeout(() => {
          const getclick = document.querySelectorAll(".accordion-new-clone")
          getac[i].classList.add("accordAnimate")
          i == getac.length - 1 &&
            setTimeout(() => {
              getclick[0].click()
            }, i * 100)
        }, i * 100)
      }
  }, [props.isVisible])
  return (
    <AccordionWithHeader
      firstOpen={true}
      onChange={(panels, state) => console.log(panels, state)}
      actionCallback={(panels, state) => {
        props.callback(state.active[0])
        setAccInd(state.active[0])
      }}
      className="myaccord"
      style={{ boxShadow: "none" }}
    >
      {question?.map((q, i) => {
        return (
          <AccordionNode
            key={i}
            className={`qn-wrap ${i === accInd ? "active" : ""}`}
          >
            <AccordionHeader
              className="accordion-new-clone"
              style={{
                cursor: "pointer",
              }}
            >
              {q}
              <div className="arrow-wrapper">
                <SVGIcon
                  name="arrow-down"
                  className="arrow-down position-absolute"
                />
              </div>
            </AccordionHeader>
            <AccordionPanel className="card-body-new-clone" speed={300}>
              {answerrt?.[i] ? (
                <div className="content">
                  {parse(answerrt?.[i]?.html)}

                  {linkText?.[i] && slug?.[i] && (
                    <Link to={slug?.[i]}>
                      {linkText?.[i]}{" "}
                      <SVGIcon
                        name="hypertext-arrow-right"
                        className="arrow-right"
                      />
                    </Link>
                  )}
                  <div className="accord-mob-wrap d-mob mb50 mt32">
                    {images[i]?.fileName &&
                    images[i]?.fileName.split(".")?.pop() === "mp4" ? (
                      <video
                        height="100%"
                        width="100%"
                        loop="true"
                        autoplay="autoplay"
                        muted
                        className="br-10"
                      >
                        <source type="video/mp4" src={images[i]?.url}></source>
                      </video>
                    ) : (
                      <img src={images[i]?.url} className="w-100" />
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </AccordionPanel>
          </AccordionNode>
        )
      })}
    </AccordionWithHeader>
  )
}
export default NewAccordian
